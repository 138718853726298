import { forEach } from 'jszip';
import { TipoDocumento } from './../../Components/documentos-relacionados/shared/documentos-relacionados.model';
'use strict';
import { Component, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { FormGroup, FormControl, FormBuilder, FormArray } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, debounceTime, switchMap } from 'rxjs/operators';

import { FaturaService } from '../shared/fatura.service';
import { FaturaItemComponent } from '../fatura-item/fatura-item.component';

import { DocumentoCadastroComponent } from '../../documento/documento-cadastro/documento-cadastro.component';
import { InfoDialogComponent } from '../../core/dialogs/info-dialog/info-dialog.component';
import { ConfirmDialogComponent } from '../../core/dialogs/confirm-dialog/confirm-dialog.component';

import { HtmlPrinterService } from '../../core/html-printer/html-printer.service';

import { Fatura } from '../shared/fatura.model';
import { ContaCadastroComponent } from '../../financeiro/conta-cadastro/conta-cadastro.component';
import { FaturaParcela } from '../shared/fatura-parcela.model';
import { NfseCadastroComponent } from 'src/app/fiscal/nfse-cadastro/nfse-cadastro.component';
import { EnviarEmailDialogComponent } from '../enviar-email-dialog/enviar-email-dialog.component';
import { NfeCadastroComponent } from 'src/app/fiscal/nfe-cadastro/nfe-cadastro.component';
import { of } from 'rxjs';
import { ConfirmacaoDivergenciaCservicoDialogComponent } from '../confirmacao-divergencia-cservico-dialog/confirmacao-divergencia-cservico-dialog.component';
import { DescontarRetencaoDialogComponent } from '../descontar-retencao-dialog/descontar-retencao-dialog.component';

export interface DialogData {
  faturaId: number;
  faturaTipo: number;
  faturaTipoDocumento: number;
}

@Component({
  selector: 'fatura-cadastro',
  templateUrl: 'fatura-cadastro.component.html',

})
export class FaturaCadastroComponent {
  fatura: any = {};
  empresa: any = {};
  parceiro: any = {};
  empresas: any = [];
  faturaEmpresas: any = [];
  grupoContas: any = [];
  centroCustos: any = [];
  caixas: any = [];
  faturas: any = [];
  parceiros: any[];
  faturaParcelas: any[];
  faturaCadastroForm: FormGroup;
  maximizado: boolean;
  tpFatura: number = 0;
  textoFatura: string = 'Venda';
  textoConta: string = 'contareceber';
  textoContaView: string = 'Conta a Receber';
  permiteAlteracao: boolean = false;
  permiteFaturamento: boolean = false;
  itemAdicionado: boolean = false;
  filteredProdutos: any[];
  parcelas: any[];
  formParcelas: FormGroup;
  faturaNfsesAndContaDto = {
    faturaId: 0,
    nfseIds: [],
    contaParcelaIds: [],
    contaIds: []
  };

  itemsGroupedDto: any;

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<FaturaCadastroComponent>,
    private _formBuilder: FormBuilder,
    private htmlPrinterService: HtmlPrinterService,
    @Inject(MAT_DIALOG_DATA) public IFatura: DialogData,
    private faturaService: FaturaService
  ) { }

  ngOnInit() {
    this.maximizado = false;

    this.faturaCadastroForm = this._formBuilder.group(new Fatura());

    if (this.IFatura.faturaTipo == 0) {
      //this.textoTpFatura = 'faturavenda';
      this.textoFatura = 'Venda';
      this.textoConta = 'contareceber';
      this.textoContaView = 'Conta a Receber';
    } else {
      this.tpFatura = 1;
      //this.textoTpFatura = 'faturacompra';
      this.textoFatura = 'Compra';
      this.textoConta = 'contapagar';
      this.textoContaView = 'Conta a Pagar';
    }

    if (this.IFatura.faturaId > 0) {
      this.buscarFatura(this.IFatura.faturaId);
    } else {
      this.fatura = {};

      this.fatura.faturaTipo = this.IFatura.faturaTipo;

      this.fatura.faturaId = 0;

      this.faturaCadastroForm
        .get('faturaTipo')
        .setValue(this.fatura.faturaTipo);

    }


    this.buscarEmpresas(this.tpFatura);


    this.faturaCadastroForm.addControl(
      'parceiroAutoComplete',
      new FormControl('')
    );

    this.onChanges();

    this.restaurarDialog();
    this.faturaCadastroForm.get('valorLiquido').disable();

    if (this.IFatura.faturaTipoDocumento == 0) {
      this.faturaCadastroForm.value.documentoFiscal = 3;
    }
  }

  onChanges(): void {
    this.faturaCadastroForm
      .get('parceiroAutoComplete')
      .valueChanges.pipe(
        debounceTime(300),
        switchMap((value) =>
          value?.length >= 3
            ? this.faturaService.getParceiroPorNome(value, this.tpFatura)
            : []
        )
      )
      .subscribe((data) => (this.parceiros = data.body));

    this.faturaCadastroForm.get('empresaId').valueChanges.subscribe((value) => {
      if (value) {
        this.getgrupoContas(this.tpFatura, value);
        this.getCaixas(this.tpFatura, value);
        this.getCentroCustos(this.tpFatura, value);
      }
    });

    this.faturaCadastroForm.get('valorLiquido').disable();
  }

  buscarEmpresas(faturaTipo: number) {
    this.faturaService.getEmpresas(faturaTipo).subscribe((data) => {
      this.empresas = data.body;
    });
  }

  selecionaCadastroFinanceiroEmpresa(empresa) {
    this.faturaCadastroForm.get('caixaId').setValue(empresa.caixaIdentificador);
    this.faturaCadastroForm.get('grupoContaId').setValue(empresa.grupoContaIdentificador);
    this.faturaCadastroForm.get('centroCustoId').setValue(empresa.centroCustoIdentificador);
    this.faturaEmpresas.push(empresa);
  }

  getgrupoContas(faturaTipo: number, empresaId: number) {
    this.faturaService.getgrupoContas(faturaTipo, empresaId).subscribe((data) => {
      this.grupoContas = data.body;
    });
  }

  getCaixas(faturaTipo: number, empresaId: number) {
    this.faturaService.getCaixas(faturaTipo, empresaId).subscribe((data) => {
      this.caixas = data.body;
    });
  }

  getCentroCustos(faturaTipo: number, empresaId: number) {
    this.faturaService.getCentroCustos(faturaTipo, empresaId).subscribe((data) => {
      this.centroCustos = data.body;
    });
  }

  fecharDialog() {
    this.dialogRef.close();
  }

  maximizarDialog() {
    this.dialogRef.updateSize('100%', '100%');
    this.maximizado = true;
  }

  restaurarDialog() {
    let innerWidth = window.innerWidth;

    if (innerWidth < 1024) {
      this.dialogRef.updateSize('100%', '100vh');
    } else {
      this.dialogRef.updateSize('80%', '100vh');
    }

    this.maximizado = false;
  }

  buscarFatura(faturaId: number) {
    this.faturaService.getFatura(faturaId).subscribe(
      (data) => {
        this.InsereDadosFatura(data.body);
      },
      (error: HttpErrorResponse) => {
        const dialogRef = this.dialog.open(InfoDialogComponent, {
          data: {
            titulo: 'Erro',
            erro: error,
          },
        });
      }
    );
  }

  cancelarFatura(cancelarId) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titulo: 'Confirmação',
        mensagem:
          'Deseja realmente Cancelar essa fatura? \n' +
          'Esse processo também irá cancelar a Conta e Boletos vinculados se houver. \n' +
          'Não serão cancelados boletos ou parcelas vencidas',
      },
      maxWidth: '200px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.faturaService.deleteFatura(cancelarId).subscribe(
          (data) => {
            const dialogRef = this.dialog.open(InfoDialogComponent, {
              data: {
                titulo: 'Sucesso',
                mensagem: 'Registro Cancelado com Sucesso',
              },
            });

            this.buscarFatura(cancelarId);
          },
          (error: HttpErrorResponse) => {
            const dialogRef = this.dialog.open(InfoDialogComponent, {
              data: {
                titulo: 'Erro',
                erro: error,
              },
            });
          }
        );
      }
    });
  }

  imprimir(): void {
    this.faturaService
      .postImprimir(this.faturaCadastroForm.value)
      .subscribe((data) => {
        let titulo = 'Fatura';

        this.htmlPrinterService.printHtmlString(data.body.htmlString, titulo);
      });
  }

  PreencherTabela(cliente: any) {
    if (cliente != undefined) {
      this.fatura.clienteId = cliente.clienteId;
    }
  }

  modalItem(faturaItemId: number): void {
    const dialogRef = this.dialog.open(FaturaItemComponent, {
      data: {
        faturaItemId: faturaItemId,
        fatura: this.faturaCadastroForm.value,
      },
      maxHeight: '100vh'
    });


    dialogRef.afterClosed().subscribe(result => {
      this.buscarFatura(this.fatura.faturaId);
    });

  }

  excluiItem(faturaItemId) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titulo: "Confirmação",
        mensagem: "Deseja realmente excluir esse item?"

      }

    });
    if (this.fatura.faturaItens.length == 0) {
      this.itemAdicionado = false;
    }

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.faturaService.deleteFaturaItem(faturaItemId).subscribe(data => {
          this.buscarFatura(this.fatura.faturaId);



        }, (error: HttpErrorResponse) => {
          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Erro",
              erro: error
            }
          });
        });
      }
    });
  }

  DeleteFaturaParcela(faturaParcelaId) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titulo: 'Confirmação',
        mensagem: 'Deseja realmente excluir essa parcela?',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.faturaService.deleteFaturaParcela(faturaParcelaId).subscribe(
          (data) => {
            this.buscarFatura(this.fatura.faturaId);
          },
          (error: HttpErrorResponse) => {
            const dialogRef = this.dialog.open(InfoDialogComponent, {
              data: {
                titulo: 'Erro',
                erro: error,
              },
            });
          }
        );
      }
    });
  }

  CalculaValorSemRetencoes() {
    this.faturaCadastroForm.get('valorSemRetencoes').value;

    if (this.faturaCadastroForm.get('valorLiquido').value > 0) {
      var valorSemRetencoes = this.faturaCadastroForm.get('valorLiquido').value;

      if (this.faturaCadastroForm.get('issRetido').value) {
        valorSemRetencoes =
          valorSemRetencoes - this.faturaCadastroForm.get('valorIss').value;
      }

      if (this.faturaCadastroForm.get('pisRetido').value) {
        valorSemRetencoes =
          valorSemRetencoes - this.faturaCadastroForm.get('valorPis').value;
      }

      if (this.faturaCadastroForm.get('cofinsRetido').value) {
        valorSemRetencoes =
          valorSemRetencoes - this.faturaCadastroForm.get('valorCofins').value;
      }

      if (this.faturaCadastroForm.get('csllRetido').value) {
        valorSemRetencoes =
          valorSemRetencoes - this.faturaCadastroForm.get('valorCsll').value;
      }

      if (this.faturaCadastroForm.get('irRetido').value) {
        valorSemRetencoes =
          valorSemRetencoes - this.faturaCadastroForm.get('valorIr').value;
      }

      if (this.faturaCadastroForm.get('inssRetido').value) {
        valorSemRetencoes =
          valorSemRetencoes - this.faturaCadastroForm.get('valorInss').value;
      }

      this.faturaCadastroForm
        .get('valorSemRetencoes')
        .setValue(valorSemRetencoes);
    }
  }

  VisualizaFluxoDocumentos(): void {
    const dialogRef = this.dialog.open(DocumentoCadastroComponent, {
      data: {
        tipoOrigem: 8,
        idDocumento: this.fatura.faturaId,
      },
      maxHeight: '100vh',
    });
  }

  verificarFaturaTipo(faturaTipo: number) {
    if (faturaTipo == 0) {
      this.textoFatura = 'Venda';
      this.textoConta = 'contareceber';
      this.textoContaView = 'Conta a Receber';
    } else {
      this.tpFatura = 1;
      this.textoFatura = 'Compra';
      this.textoConta = 'contapagar';
      this.textoContaView = 'Conta a Pagar';
    }
  }

  verificaFaturaStatus(faturaStatus: number) {
    return this.faturaService.verificaFaturaStatus(faturaStatus);
  }

  GerarParcelas(Fatura) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titulo: 'Gerar Novas Parcela',
        mensagem:
          'Esse procedimento irá excluir as parcelas atuais e irá criar novas. Deseja continuar?',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.salvarParcelas(Fatura);
      }
    });
  }

  salvarParcelas(_fatura: any) {
    this.faturaService.postGerarParcelas(_fatura).subscribe((data) => {
      const dialogRef = this.dialog.open(InfoDialogComponent, {
        data: {
          titulo: 'Sucesso',
          mensagem: 'Parcelas geradas com sucesso',
        },
      });

      this.buscarFatura(_fatura.faturaId);
    });
  }

  async gerarNfseConta() {
    try {
      const data = await this.faturaService
        .postVerificaCServicoDiferentes(this.faturaCadastroForm.value)
        .toPromise();
      this.itemsGroupedDto = data.body;
    } catch (error) {
      const dialogRef = this.dialog.open(InfoDialogComponent, {
        data: {
          titulo: 'Erro',
          erro: error,
        },
      });
    }

    let valorDivergencia = 0;
    let valorTotalDosServicosNfses = 0;
    let quantidadeNotas = 0;

    if (this.itemsGroupedDto.length > 0) {

      try {
        const buscaDivergencia = await this.faturaService
          .postBuscaValorRetencaoNfses(this.itemsGroupedDto)
          .toPromise();
        buscaDivergencia.body.forEach((item) => {
          valorDivergencia += item.valorServicos - item.valorLiquidoNfse;
          valorTotalDosServicosNfses += item.valorServicos;
        });

        quantidadeNotas = buscaDivergencia.body.length;
      } catch (error) {
        const dialogRef = this.dialog.open(InfoDialogComponent, {
          data: {
            titulo: 'Erro',
            erro: error,
          },
        });
      }

    }


    const mensagem = `Esta(s) nota(s) fiscal(is) conterá(ão) o valor de R$ ${valorDivergencia.toFixed(2)} em retenções. Deseja descontar o valor dessas retenções no boleto e na conta a receber gerados?`

    if (valorDivergencia > 0) {
      const confDialog = this.dialog.open(DescontarRetencaoDialogComponent, {
        data: {
          mensagem: mensagem,
        },
      });




      confDialog.afterClosed().subscribe((result) => {

        const documentoFiscal = this.faturaCadastroForm.value.documentoFiscal;
        const atualizaValoresRetencao = result == 1;

        if ((result == 1 || result == 2) && documentoFiscal == 2) {
          this.addNfse(true, atualizaValoresRetencao);
        }

        if ((result == 1 || result == 2) && documentoFiscal == 1) {
          if (this.itemsGroupedDto.length > 1) {
            const dialogConfirmaDivergencia = this.dialog.open(
              ConfirmacaoDivergenciaCservicoDialogComponent,
              {
                data: {
                  faturaItensCodigoServicoDto: this.itemsGroupedDto,
                },
                maxHeight: '100vh',
                maxWidth: '20vw',
              }
            );

            dialogConfirmaDivergencia.afterClosed().subscribe((dialogResult) => {
              if (dialogResult) {

                this.addNfse(true, atualizaValoresRetencao);
              }
            });
          } else {
            this.addNfse(true, atualizaValoresRetencao);
          }
        }
      });
    }
  }

  gerarNfeConta() {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titulo: "Gerar Conta",
        mensagem: "Esse procedimento irá gerar uma NF-e e " +
          "criar a Conta a Receber." +
          "Deseja continuar?"
      }
    });

    dialogRef.afterClosed().subscribe(async result => {

      if (result) {
        this.gerarConta();
        this.gerarNFe(this.fatura.faturaId)
      }
    });
  }

  async deconstaValoreRetencoes() {
    let valorDivergencia = 0;
    let valorTotalDosServicosNfses = 0;
    let quantidadeNotas = 0;

    if (this.itemsGroupedDto.length > 0) {
      try {
        const buscaDivergencia = await this.faturaService
          .postBuscaValorRetencaoNfses(this.itemsGroupedDto)
          .toPromise();
        buscaDivergencia.body.forEach((item) => {
          valorDivergencia += item.valorServicos - item.valorLiquidoNfse;
          valorTotalDosServicosNfses += item.valorServicos;
        });

        quantidadeNotas = buscaDivergencia.body.length;
      } catch (error) {
        this.dialog.open(InfoDialogComponent, {
          data: {
            titulo: 'Erro',
            erro: error,
          },
        });
      }
    }

    const mensagem = `Foi identificada uma retenção no valor total de R$ ${valorDivergencia.toFixed(2)}. Deseja aplicar esse valor como desconto nas contas a receber geradas?`;

    if (valorDivergencia > 0) {
      const confDialog = this.dialog.open(DescontarRetencaoDialogComponent, {
        data: {
          mensagem: mensagem,
        },
      });

      const result = await confDialog.afterClosed().toPromise();
      return result;
    }

    return null;
  }

  async gerarConta(geraSomenteNfse: boolean = false) {

    if (geraSomenteNfse && this.faturaCadastroForm.value.documentoFiscal == 1) {
      const resultado = await this.verificaDivergenciasCodigoServicosItens();

      if (!resultado) {
        return;
      }
    }

    this.faturaService
      .postGerarConta(this.faturaCadastroForm.value, false)
      .subscribe(
        (data) => {
          this.faturaNfsesAndContaDto.contaParcelaIds = data.body.contaParcelaIds;

          if (this.faturaNfsesAndContaDto?.contaParcelaIds?.length > 1) {
            this.openContAReceberNextTab();
          } else {
            this.abreModalConta(
              this.faturaCadastroForm.value.faturaTipo,
              data.body.contaIds[0],
              this.faturaCadastroForm.value.faturaTipo
            );
          }


          this.buscarFatura(this.fatura.faturaId);
        },
        (error: HttpErrorResponse) => {
          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: 'Erro',
              erro: error,
            },
          });
        }
      );
  }


  async gerarContaEBoleto(atualizaValoresRetencao: boolean, geraSomenteNfse: boolean = false) {

    if (geraSomenteNfse) {
      const resultado = await this.verificaDivergenciasCodigoServicosItens();

      if (!resultado) {
        return;
      }
    }

    this.faturaService
      .postGerarContaBoleto(this.faturaCadastroForm.value, atualizaValoresRetencao)
      .subscribe(
        (data) => {
          this.faturaNfsesAndContaDto.contaParcelaIds = data.body.contaParcelaIds;

          if (this.faturaNfsesAndContaDto?.contaParcelaIds?.length > 1) {
            this.openContAReceberNextTab();
          } else {
            this.abreModalConta(
              this.faturaCadastroForm.value.faturaTipo,
              data.body.contaIds[0],
              this.faturaCadastroForm.value.faturaTipo
            );
          }


          this.buscarFatura(this.fatura.faturaId);
        },
        (error: HttpErrorResponse) => {
          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: 'Erro',
              erro: error,
            },
          });
        }
      );
  }

  verificaDivergenciasCodigoServicosItens(): Promise<boolean> {
    return new Promise((resolve) => {
      if (this.faturaCadastroForm.value.documentoFiscal == 2) {
        resolve(true);
      } else {
        this.faturaService.postVerificaCServicoDiferentes(this.faturaCadastroForm.value)
          .toPromise()
          .then((data) => {
            this.itemsGroupedDto = data.body;

            const dialogConfirmaDivergencia = this.dialog.open(
              ConfirmacaoDivergenciaCservicoDialogComponent,
              {
                data: {
                  faturaItensCodigoServicoDto: this.itemsGroupedDto,
                },
                maxHeight: '100vh',
                maxWidth: '20vw',
              }
            );

            dialogConfirmaDivergencia.afterClosed().subscribe((dialogResult) => {
              if (!dialogResult) {
                resolve(false);
              } else {
                resolve(true);
              }
            });
          })
          .catch((error) => {
            this.dialog.open(InfoDialogComponent, {
              data: {
                titulo: 'Erro',
                erro: error,
              },
            });
            resolve(false);
          });
      }
    });
  }

  async addNfse(gerarGerarConta: boolean, atualizaValoresRetencao: boolean, geraSomenteNfse: boolean = false) {

    if (geraSomenteNfse) {
      const resultado = await this.verificaDivergenciasCodigoServicosItens();

      if (!resultado) {
        return;
      }
    }

    this.faturaService
      .postGerarNfse(this.faturaCadastroForm.value)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.dialog.open(InfoDialogComponent, {
            data: {
              erro: error,
              mensagem: 'Erro ao gerar a NFSe',
              titulo: 'Erro'
            }
          });

          return of(null);
        })
      )
      .subscribe((data) => {
        if (data) {
          this.faturaNfsesAndContaDto.nfseIds = data.body.nfseIds;

          if (this.faturaNfsesAndContaDto.nfseIds.length > 1) {
            if (!gerarGerarConta) {
              this.openNfseNextTab();
            }
          } else {
            this.visualizarNfse(data.body.nfseIds[0]);
          }

          if (gerarGerarConta) {
            this.gerarContaEBoleto(atualizaValoresRetencao);
          }
        }
      });
  }

  getNfsesAndContasByFaturaId(faturaId: number) {
    this.faturaService.getNfsesAndContasByFaturaId(faturaId).subscribe((data) => {
      this.faturaNfsesAndContaDto = data.body;
    });
  }

  gerarNFe(faturaId): void {

    this.faturaService.postGerarNfe(faturaId).subscribe(data => {

      const dialogRef = this.dialog.open(InfoDialogComponent, {
        data: {
          titulo: "Sucesso",
          mensagem: "NF-e Gerada com Sucesso"
        }
      });

      this.VisualizaNFe(data.body.nfeId);

    });
  }

  openNfseNextTab() {
    let nfseIds = this.faturaNfsesAndContaDto.nfseIds.join(',');
    let url = `#/fiscal/nfse?nfseIds=${nfseIds}`;
    window.open(url, '_blank');

  }

  openContAReceberNextTab() {
    let contaParcelaIds = this.faturaNfsesAndContaDto.contaParcelaIds;
    let url = `#/financeiro/contareceber?contaParcelaIds=${contaParcelaIds}`;
    window.open(url, '_blank');

  }

  VisualizaNFe(nfeId: number) {

    const dialogRef = this.dialog.open(NfeCadastroComponent, {
      data: {
        nfeId: nfeId,
      },
      maxHeight: '100vh',
      maxWidth: '100vw',
      panelClass: 'custom-dialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.buscarFatura(this.IFatura.faturaId);
    });

  }

  abreModalConta(tpConta: number, contaId: number, tipoParceiro: number) {
    const dialogRef = this.dialog.open(ContaCadastroComponent, {
      data: {
        contaId: contaId,
        tpConta: tpConta,
        tipoParceiro: tipoParceiro,
      },
      maxHeight: '100vh',
      maxWidth: '100vw',
    });
  }

  DeterminaParceiro(parceiro: any) {
    if (this.tpFatura == 0) {
      this.faturaCadastroForm.get('parceiroId').setValue(parceiro.clienteId);
    } else {
      this.faturaCadastroForm.get('parceiroId').setValue(parceiro.fornecedorId);
    }
  }

  AdicionarNovaParcelaVazia() {
    var parcela: any = {};

    parcela.dataVencimento = new Date();
    parcela.valorParcela = 0;
    parcela.faturaId = this.fatura.faturaId;

    this.faturaParcelas.push(parcela);
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  EnviaEmail(faturaFormTemp: any) {
    const dialogRef = this.dialog.open(EnviarEmailDialogComponent, {
      data: {
        titulo: 'Enviar Email',
        faturaFormTemp: faturaFormTemp.value,
      },
    });
  }

  atualizarValorBruto() {
    let valorBruto = this.faturaCadastroForm.get('valorBruto').value;
    let valorDesconto = this.faturaCadastroForm.get('valorDesconto').value;

    let valorLiquido = valorBruto - valorDesconto;

    this.faturaCadastroForm.get('valorLiquido').setValue(valorLiquido);
  }

  visualizarNfse(id: number) {
    const ufId = this.empresa.municipio.uf.ufId;
    const municipioId = this.empresa.municipio.municipioId;


    const dialogRef = this.dialog.open(NfseCadastroComponent, {
      data: {
        nfseId: id,
        ufId: ufId,
        municipioId: municipioId,
      },
      maxHeight: '100vh',
    });
  }

  //Adicionando novas funcionalidade para o componente fatura

  //Função para buscar produtos por nome e empresas
  getProdutoPorNomeEEmpresasService(params: any) {
    if (params.empresasIds.length > 1) {
      params.empresasIds.splice(1, params.empresasIds.length - 1);
    }

    return this.faturaService.getProdutoPorNomeEEmpresas(params);
  }

  //Função para adicionar item na fatura
  onItensChange(itens: any[]): void {

    // Acessa o valor do array de faturaItens
    const faturaItens = itens
    // Atualiza as propriedades dos itens no array
    itens.forEach((item, index) => {
      const itemFormatado = {
        faturaParcelaId: item.faturaItemId || 0,
        descricao: item.descricao,
        qtdTributaria: item.quantidade || item.qtdTributaria,
        qtdComercial: item.quantidade || item.qtdComercial,
        codProd: item.codProd,
        faturaId: this.fatura.faturaId,
        valorDesconto: item.descontoUnitario,
        produto: item.produto || item.item,
        produtoId: item.produtoId,
        valorBruto: item.valorTotalBruto,
        valorTotal: item.valorTotalLiquido,
        vlrComercial: item.valorTotalLiquido,
        vlrTributario: item.valorTotalLiquido,
        valorUnitario: item.valorUnitario
      };

      // Atualiza o item no array existente
      if (faturaItens[index]) {
        Object.assign(faturaItens[index], itemFormatado);
      } else {
        faturaItens.push(itemFormatado);
      }
    });

    // Atualiza o valor do form control
    this.faturaCadastroForm.get('faturaItens').setValue(faturaItens, { emitEvent: false });

  }

  //Função para adicionar valor total bruto
  onValorTotalBrutoChange(valorTotalBruto: number) {
    this.faturaCadastroForm.get('valorBruto').setValue(valorTotalBruto);
  }

  //Função para adicionar descontos globalmente
  onDescontosAplicadosAosItensChange(descontosAplicadosAosItens: number) {
    this.faturaCadastroForm.get('descontosAplicadosAosItens').setValue(descontosAplicadosAosItens);
    this.faturaCadastroForm.get('somaDeTodosOsDescontos').setValue(descontosAplicadosAosItens + this.faturaCadastroForm.get('descontosAplicadosGlobalmente').value);
  }

  //Função para valor total liquido
  onValorTotalLiquidoChange(valorTotalLiquidoItens: number) {
    const valorTotalLiquido = valorTotalLiquidoItens - this.faturaCadastroForm.get('descontosAplicadosGlobalmente').value;
    this.faturaCadastroForm.get('valorLiquido').setValue(valorTotalLiquido);
    this.faturaCadastroForm.get('valorTotal').setValue(valorTotalLiquido);
  }

  //Função para adicionar parcelas
  onParcelasFinaisChange(parcelas: any[]) {
    const parcelasFinais = parcelas?.map((parcela: any) => ({
      faturaParcelaId: parcela.parcelaId,
      dataVencimento: parcela.dataVencimentoParcela,
      valorLiquido: parcela.valorLiquido,
      tipoPagamento: parcela.tipoPagamento
    }));

    this.faturaCadastroForm.get('faturaParcelas').setValue(parcelasFinais);
  }

  salvar(faturaCadastroForm: FormGroup): void {
    let faturaForm = this.faturaCadastroForm;

    var fatura = this.fatura;

    fatura.cliente = null;
    fatura.empresa = null;

    if (this.tpFatura == 0) {
      fatura.faturaTipo = 0;
    } else {
      fatura.tpFatura = 1;
    }


    if (faturaForm.value.faturaId == 0 || faturaForm.value.faturaId == null) {
      if (faturaForm.value.faturaItens.length == 0) {
        this._snackBar.open('É necessário adicionar ao menos um item na fatura.', 'Fechar', {
          duration: 5000,
          panelClass: ['mat-toolbar', 'warn']
        });
        return;
      }

      //estabelece o número de parcelas
      faturaForm.value.NumeroParcelas = faturaForm.value.faturaParcelas.length;

      //seta os valores dos itens da fatura
      faturaForm.value.faturaItens.forEach(item => {
        item.qtdComercial = item.quantidade;
        item.qtdTributaria = item.quantidade;
        item.valorUnitarioComercial = item.valorUnitario;
        item.valorUnitarioTributavel = item.valorUnitario;
        item.ValorUnitario = item.valorUnitario;
        item.valorBruto = item.valorUnitario * item.quantidade;
        item.valorServicos = item.valorBruto;
        item.valorDesconto = item.descontoUnitario * item.quantidade;
        item.valorTotal = item.valorUnitario * item.quantidade;
        item.valorTotalLiquido = item.valorTotal - item.valorDesconto;
        item.grupoContaId = faturaForm.value.grupoContaId;
      });


      //verificar se as parcelas estão preenchidas
      if (faturaForm.value.faturaParcelas.length == 0) {
        this._snackBar.open('É necessário adicionar ao menos uma parcela na fatura.', 'Fechar', {
          duration: 5000,
          panelClass: ['mat-toolbar', 'warn']
        });
        return;
      }

      //pegar os valores das parcelas
      this.parcelas = faturaForm.value.faturaParcelas;

      //pegar o tipo de pagamento das parcelas e salva no tipo de pagamento da fatura
      faturaForm.value.tipoPagamento = this.parcelas[0].tipoPagamento;

      //seta os valores das parcelas
      this.parcelas.forEach(parcela => {
        parcela.faturaParcelaId = 0;
        parcela.parcelaId = 0;
        parcela.dataVencimento = parcela.dataVencimento;
        parcela.valorParcela = parcela.valorLiquido;
        parcela.valorBruto = parcela.valorLiquido;

      });

      faturaForm.value.valorLiquido = faturaForm.value.valorTotal;
      faturaForm.value.faturaParcelas = this.parcelas;
      faturaForm.value.faturaTipoDocumento = this.IFatura.faturaTipoDocumento;

      this.faturaService.NovaFatura(faturaForm.value).subscribe((data) => {
        this._snackBar.open('Fatura criado com sucesso!', 'Fechar', {
          duration: 5000,
          panelClass: ['mat-toolbar', 'sucess']
        });

        this.buscarFatura(data.body.faturaId);
      });
    } else {
      let faturaTemp = faturaForm.value;

      faturaTemp.faturaParcelas = faturaForm.value.faturaParcelas;

      //adicionar os valores das parcelas
      faturaTemp.faturaParcelas.forEach(parcela => {
        parcela.dataVencimento = parcela.dataVencimento;
        parcela.valorParcela = parcela.valorLiquido;
        parcela.valorBruto = parcela.valorLiquido;
      });

      this.faturaService
        .putFatura(faturaTemp, faturaTemp.faturaId)
        .subscribe((data) => {
          this._snackBar.open('Registro Salvo com Sucesso');

          this.buscarFatura(faturaForm.value.faturaId);
        });
    }
  }

  InsereDadosFatura(data: any) {
    this.faturaCadastroForm.patchValue(data.fatura);
    this.faturaCadastroForm
      .get('parceiroAutoComplete')
      .setValue(data.parceiro.nomeFantasia);

    this.fatura = data.fatura;

    this.empresa = data.empresa;
    this.parceiro = data.parceiro;

    // Seta os valores dos itens da fatura
    data.fatura.faturaItens.forEach(item => {
      item.quantidade = item.qtdComercial;
      item.descontoUnitario = item.valorDesconto / item.quantidade;
      item.valorTotalLiquido = item.valorTotal;
      item.valorTotalBruto = item.valorBruto;
    });

    let parcelas = data.fatura.faturaParcelas;

    this.faturaParcelas = parcelas.map(parcela => ({
      dataVencimento: new Date(parcela.dataVencimento),
      faturaParcelaId: parcela.faturaParcelaId,
      valorLiquido: parcela.valorParcela,
      tipoPagamento: this.fatura.tipoPagamento
    }));

    this.faturaCadastroForm.get('faturaParcelas').setValue(this.faturaParcelas);

    this.verificarFaturaTipo(this.fatura.faturaTipo);
    this.getgrupoContas(this.tpFatura, this.empresa.empresaId);

    this.faturas = [this.fatura];

    if (this.fatura.faturaStatus == 4 || this.fatura.faturaStatus == 99 || this.fatura.faturaStatus == 1) {
      this.faturaCadastroForm.disable();
      this.permiteAlteracao = false;
    }

    if (this.fatura.faturaId > 0 && this.fatura.contaId == 0) {
      this.permiteFaturamento = true;
    } else {
      this.permiteFaturamento = false;
    }

    this.faturaCadastroForm.value.valorLiquido = 0;

    for (let i = 0; i < this.faturas[0].faturaItens.length; i++) {
      this.faturaCadastroForm.value.valorLiquido +=
        this.faturas[0].faturaItens[i].valorLiquido;
    }

    this.itemAdicionado = this.fatura.faturaItens.length > 0;

    this.faturaEmpresas.push(this.empresa);
    this.getNfsesAndContasByFaturaId(this.fatura.faturaId);
  }
}
